<template>
	<div class="control_box" >
		<!-- 설정온도 -->
		<div class="box temperature">
			<dl>
				<!-- <dt> -->
				<!-- 실내온도 : -->
				<!-- {{  !liveData[ctrPtAddr.split("_")[0]+"_ROOMTEMP"] ? "0 ℃": liveData[ctrPtAddr.split("_")[0]+"_ROOMTEMP"].ptVal !== null ? liveData[ctrPtAddr.split("_")[0]+"_ROOMTEMP"].ptVal + " ℃" : "0 ℃" }} -->
				<!-- {{ ROOMTEMP.ptVal}}
				</dt> -->

				<dt>
					{{ $t("설정온도") }} :
					<!-- {{  !liveData[ctrPtAddr.split("_")[0]+"_ROOMTEMP"] ? "0 ℃": liveData[ctrPtAddr.split("_")[0]+"_ROOMTEMP"].ptVal !== null ? liveData[ctrPtAddr.split("_")[0]+"_ROOMTEMP"].ptVal + " ℃" : "0 ℃" }} -->
					{{ Number(SETTEMP.ptVal).toFixed(0) + " ℃" }}
				</dt>
				<!-- <dt>실내온도 : {{ TEMP.ptVal + " ℃" }}</dt> -->
				<!-- <dt></dt>
        <dt>설정온도</dt> -->
				<dd>
					<strong>{{ Number(TEMP.ptVal).toFixed(0) + " ℃" }}</strong>
					<!-- <span> ℃</span> -->
				</dd>
			</dl>
		</div>
		<!-- 업 버튼 -->
		<div
			class="box btn_und"
			:class="!ctrlPropList.find((v) => v.propCode === 'SETTEMP') || IsOn === 0 ? 'ulPointDisabeld' : ''"
			:style="{
				width: '100%',
				height: '100%',
				display: 'inline-flex',
				alignSelf: 'center',
			}"
		>
			<!-- <div
			class="box btn_und"
			:style="{
				width: '100%',
				height: '100%',
				display: 'inline-flex',
				alignSelf: 'center',
			}"
		> -->
			<a key="" @click="onSetTempUp" :style="{ margin: 'auto' }"><img src="/assets/xems/common/views/equip-control/ehp/icon_up.png" alt=""/></a>
		</div>
		<!-- 모드 -->
		<div class="box sub_tit">{{ $t("모드") }}</div>
		<div class="box control_btn">
			<ul class="mord" :class="!ctrlPropList.find((v) => v.propCode === 'MODE') || IsOn === 0 ? 'ulPointDisabeld' : ''">
				<!-- <ul class="mord"> -->
				<li @click="onModeClick(4)">
					<a v-if="MODE.ptVal != 4"><img src="/assets/xems/common/views/equip-control/ehp/icon_mode01.png" alt=""/></a>
					<a v-if="MODE.ptVal == 4"><img src="/assets/xems/common/views/equip-control/ehp/icon_mode01_on.png" alt=""/></a>
					<span>{{ $t("자동") }}</span>
				</li>
				<li @click="onModeClick(1)">
					<a v-if="MODE.ptVal != 1"><img src="/assets/xems/common/views/equip-control/ehp/icon_mode02.png" alt=""/></a>
					<a v-if="MODE.ptVal == 1"><img src="/assets/xems/common/views/equip-control/ehp/icon_mode02_on.png" alt=""/></a>
					<span>{{ $t("냉방") }}</span>
				</li>
				<li @click="onModeClick(5)">
					<a v-if="MODE.ptVal != 5"><img src="/assets/xems/common/views/equip-control/ehp/icon_mode03.png" alt=""/></a>
					<a v-if="MODE.ptVal == 5"><img src="/assets/xems/common/views/equip-control/ehp/icon_mode03_on.png" alt=""/></a>
					<span>{{ $t("난방") }}</span>
				</li>
				<li @click="onModeClick(2)">
					<a v-if="MODE.ptVal != 2"><img src="/assets/xems/common/views/equip-control/ehp/icon_mode04.png" alt=""/></a>
					<a v-if="MODE.ptVal == 2"><img src="/assets/xems/common/views/equip-control/ehp/icon_mode04_on.png" alt=""/></a>
					<span>{{ $t("제습") }}</span>
				</li>
				<li @click="onModeClick(3)">
					<a v-if="MODE.ptVal != 3"><img src="/assets/xems/common/views/equip-control/ehp/icon_mode05.png" alt=""/></a>
					<a v-if="MODE.ptVal == 3"><img src="/assets/xems/common/views/equip-control/ehp/icon_mode05_on.png" alt=""/></a>
					<span>{{ $t("송풍") }}</span>
				</li>
			</ul>
		</div>

		<!-- 다운 버튼 -->
		<div
			class="box btn_und"
			:class="!ctrlPropList.find((v) => v.propCode === 'SETTEMP') || IsOn === 0 ? 'ulPointDisabeld' : ''"
			:style="{
				width: '100%',
				height: '100%',
				display: 'inline-flex',
				alignSelf: 'center',
			}"
		>
			<!-- <div
			class="box btn_und"
			:style="{
				width: '100%',
				height: '100%',
				display: 'inline-flex',
				alignSelf: 'center',
			}"
		> -->
			<a @click="onSetTempDown" :style="{ margin: 'auto' }"><img src="/assets/xems/common/views/equip-control/ehp/icon_down.png" alt=""/></a>
		</div>
		<!-- 풍량 -->
		<div class="box sub_tit">{{ $t("풍량") }}</div>
		<div class="box control_btn">
			<ul class="air" :class="!ctrlPropList.find((v) => v.propCode === 'FANSPEED') || IsOn === 0 ? 'ulPointDisabeld' : ''">
				<!-- <ul class="air"> -->
				<li @click="onFanClick(4)">
					<a v-if="FANSPEED.ptVal != 4"><img src="/assets/xems/common/views/equip-control/ehp/icon_air01.png" alt=""/></a>
					<a v-if="FANSPEED.ptVal == 4"><img src="/assets/xems/common/views/equip-control/ehp/icon_air01_on.png" alt=""/></a>
					<span>{{ $t("자동") }}</span>
				</li>
				<li @click="onFanClick(1)">
					<a v-if="FANSPEED.ptVal != 1"><img src="/assets/xems/common/views/equip-control/ehp/icon_air02.png" alt=""/></a>
					<a v-if="FANSPEED.ptVal == 1"><img src="/assets/xems/common/views/equip-control/ehp/icon_air02_on.png" alt=""/></a>
					<span>1{{ $t("단계") }}</span>
				</li>
				<li @click="onFanClick(2)">
					<a v-if="FANSPEED.ptVal != 2"><img src="/assets/xems/common/views/equip-control/ehp/icon_air03.png" alt=""/></a>
					<a v-if="FANSPEED.ptVal == 2"><img src="/assets/xems/common/views/equip-control/ehp/icon_air03_on.png" alt=""/></a>
					<span>2{{ $t("단계") }}</span>
				</li>
				<li @click="onFanClick(3)">
					<a v-if="FANSPEED.ptVal != 3"><img src="/assets/xems/common/views/equip-control/ehp/icon_air04.png" alt=""/></a>
					<a v-if="FANSPEED.ptVal == 3"><img src="/assets/xems/common/views/equip-control/ehp/icon_air04_on.png" alt=""/></a>
					<span>3{{ $t("단계") }}</span>
				</li>
			</ul>
		</div>
		<!-- <div v-for="(data, i) in ">

    </div> -->

		<div v-if="Number(IsOn) === 1" class="box btn_application d-flex">
			<button v-show="isShow === true" @click="onSave" type="button" class="application">
				{{ $t("적용하기") }}
			</button>
			<button v-show="isShow === true" @click="resetVal" type="button" class="application">
				{{ $t("취소") }}
			</button>
			<button v-show="isShow === false" type="button" class="application"></button>
		</div>
		<div v-else class="box btn_application">
			<button type="button" class="application"></button>
		</div>
	</div>
</template>

<style lang="scss">
	.ehpDetail {
		border: 1px solid #ffffff;
		.containerRow {
			padding: 5px;
			width: 100%;
			background-color: #000000;
			color: #ffffff;
			.temperature {
				align-self: center;
				text-align: center;
				dl {
					dt,
					dd {
						line-height: 100%;
					}
					dt {
						font-size: 12px;
						font-weight: 700;
						letter-spacing: -0.5px;
						padding-bottom: 8px;
					}
					dd {
						font-size: 30px;
						letter-spacing: -2px;
						strong,
						span {
							display: inline-block;
							font-weight: 400;
							margin-right: -4px;
						}
					}
				}
			}

			.row {
				.mord {
					.col {
						div {
							text-align: center;
						}
					}
				}
				.air {
					.col {
						div {
							text-align: center;
						}
					}
				}
			}
		}
	}

	.ulPointDisabeld {
		pointer-events: none;
		opacity: 0.6;
	}
</style>

<script>
	import backEndApi from "@api/backEndApi.js";
	import AppErrorHandler from "@src/appErrorHandler";
	import * as popupMessages from "@src/consts/popupMessageConsts";

	export default {
		components: {},
		props: ["ctrlPropList", "propList", "ctrPtAddr", "IsOn", "liveData"],
		data() {
			return {
				isShow: false,
				setPropList: [],
				otherProp: [],
				testText: [],
				TEMP: {
					ctrlPropYn: null,
					ptAddr: null,
					ptVal: 0,
				},
				FANSPEED: {
					ctrlPropYn: null,
					ptAddr: null,
					ptVal: 0,
				},
				FirstFANSPEED: {
					ctrlPropYn: null,
					ptAddr: null,
					ptVal: 0,
				},
				MODE: {
					ctrlPropYn: null,
					ptAddr: null,
					ptVal: 0,
				},
				FirstMode: {
					ctrlPropYn: null,
					ptAddr: null,
					ptVal: 0,
				},
				FirstSetTemp: {
					ctrlPropYn: null,
					ptAddr: null,
					ptVal: 0,
				},
				SETTEMP: {
					ctrlPropYn: null,
					ptAddr: null,
					ptVal: 0,
				},
				ROOMTEMP: {
					ctrlPropYn: null,
					ptAddr: null,
					ptVal: 0,
				},
				isEdit: false,
				popupMessages,
			};
		},
		computed: {
			locale() {
				return this.$store.state.locale;
			},

			detailCtrlPropList() {
				let data = [];

				for (let i = 0; i < this.ctrlPropList.length; i++) {
					if (this.ctrlPropList[i].propCode !== "ONOFF") {
						data.push(this.ctrlPropList[i]);
					}
				}

				return data;
			},
			tempEHPCtrlValue() {
				return this.$store.getTempEHPCtrlValue;
			},
		},
		created() {
			this.createGetTEMP();
		},
		destroyed() {
			// this.$store.commit("UPDATE_TOTAL_REAL_LOCAL", this.FirstFANSPEED);
			// this.$store.commit("UPDATE_TOTAL_REAL_LOCAL", this.FirstMode);
			// this.$store.commit("UPDATE_TOTAL_REAL_LOCAL", this.FirstSetTemp);
			// this.$store.commit("EQUIP_INFO_DELETE", this.FirstFANSPEED.ptAddr);
			// this.$store.commit("EQUIP_INFO_DELETE", this.FirstMode.ptAddr);
			// this.$store.commit("EQUIP_INFO_DELETE", this.FirstSetTemp.ptAddr);
		},
		mounted() {
			this.init();
		},
		watch: {
			IsOn(value) {
				if (value === 0) {
					this.isShow = false;
					this.isEdit = false;
				}

				this.init();
			},
			liveData() {
				this.init();
			},
			propList() {
				this.init();
			},
		},
		methods: {
			createGetTEMP() {
				this.propList.forEach((prop) => {
					if (prop.equipPropCode === "TEMP") {
						this.TEMP.ctrlPropYn = prop.ctrlPropYn;
						this.TEMP.ptAddr = prop.ptAddr;
						this.TEMP.ptVal = Number(prop.ptVal);
					}
				});
			},
			init() {
				// console.log(this.MODE);
				// console.log(this.MODE.ptAddr)
				// console.log(this.liveData);
				// if (Number(this.IsOn) === 1) {

				if (this.isEdit === false) {
					this.propList.forEach((prop) => {
						if (prop.equipPropCode == "FANSPEED") {
							this.FANSPEED.ctrlPropYn = prop.ctrlPropYn;
							this.FANSPEED.ptAddr = prop.ptAddr;
							this.FANSPEED.ptVal = this.liveData[this.FANSPEED.ptAddr] ? this.liveData[this.FANSPEED.ptAddr].ptVal : Number(prop.ptVal);
							// this.FANSPEED.ptVal = Number(prop.ptVal);
							if (this.isShow === false) {
								this.FirstFANSPEED = JSON.parse(JSON.stringify(this.FANSPEED));
							}
						} else if (prop.equipPropCode == "MODE") {
							this.MODE.ctrlPropYn = prop.ctrlPropYn;
							this.MODE.ptAddr = prop.ptAddr;
							this.MODE.ptVal = this.liveData[this.MODE.ptAddr] ? this.liveData[this.MODE.ptAddr].ptVal : Number(prop.ptVal);
							// this.MODE.ptVal = Number(prop.ptVal);
							if (this.isShow === false) {
								this.FirstMode = JSON.parse(JSON.stringify(this.MODE));
							}
						} else if (prop.equipPropCode == "SETTEMP") {
							this.SETTEMP.ctrlPropYn = prop.ctrlPropYn;
							this.SETTEMP.ptAddr = prop.ptAddr;
							this.SETTEMP.ptVal = this.liveData[this.SETTEMP.ptAddr] ? this.liveData[this.SETTEMP.ptAddr].ptVal : Number(prop.ptVal);
							// this.SETTEMP.ptVal = Number(prop.ptVal);
							if (this.isShow === false) {
								this.FirstSetTemp = JSON.parse(JSON.stringify(this.SETTEMP));
							}
						} else if (prop.equipPropCode === "TEMP") {
							this.TEMP.ctrlPropYn = prop.ctrlPropYn;
							this.TEMP.ptAddr = prop.ptAddr;
							this.SETTEMP.ptVal = this.liveData[this.TEMP.ptAddr] ? this.liveData[this.TEMP.ptAddr].ptVal : Number(prop.ptVal);
							// this.TEMP.ptVal = Number(prop.ptVal);
						} else {
							this.otherProp.push({
								ctrlPropYn: prop.ctrlPropYn,
								ptAddr: prop.ptAddr,
								ptVal: Number(prop.ptVal),
								equipPropCode: prop.equipPropCode,
							});
						}
					});
				}

				// } else {
				//   this.reset();
				// }

				// this.isValidation();
			},
			reset() {
				this.FANSPEED = {
					ctrlPropYn: null,
					ptAddr: null,
					ptVal: 0,
				};
				this.MODE = {
					ctrlPropYn: null,
					ptAddr: null,
					ptVal: 0,
				};
				this.SETTEMP = {
					ctrlPropYn: null,
					ptAddr: null,
					ptVal: 0,
				};
				this.TEMP = {
					ctrlPropYn: null,
					ptAddr: null,
					ptVal: 0,
				};
			},
			onSetTempUp() {
				if (this.SETTEMP.ptVal < 18) {
					this.SETTEMP.ptVal = 18;
				}

				let val = Number(this.SETTEMP.ptVal);

				if (val == 30) {
					this.alertNoti(popupMessages.EHP_CONTROL_TEMP_MAX_POPUP_MESSAGE);
				} else {
					val += 1;

					// if (Number(this.IsOn) === 1) {
					// this.$store.commit("UPDATE_TOTAL_REAL_LOCAL", this.SETTEMP);

					if (this.MODE.ptVal === this.FirstSetTemp.ptVal) {
						let idx = this.$store.state.equipUpdateInfo.findIndex((el) => el === this.SETTEMP.ptAddr);

						if (idx !== -1) {
							this.$store.state.equipUpdateInfo.splice(idx, 1);
						}
					} else {
						if (this.$store.state.equipUpdateInfo.includes(this.SETTEMP.ptAddr) === false) {
							this.$store.commit("EQUIP_INFO_INSERT", this.SETTEMP.ptAddr);
						}
					}
					// }
				}
				this.SETTEMP.ptVal = val;
				this.isValidation();
			},
			onSetTempDown() {
				if (this.SETTEMP.ptVal < 18) {
					this.SETTEMP.ptVal = 18;
				}

				if (this.SETTEMP.ptVal == 18) {
					this.alertNoti(popupMessages.EHP_CONTROL_TEMP_MIN_POPUP_MESSAGE);
				} else {
					this.SETTEMP.ptVal -= 1;

					// if (Number(this.IsOn) === 1) {
					// this.$store.commit("UPDATE_TOTAL_REAL_LOCAL", this.SETTEMP);

					if (this.MODE.ptVal === this.FirstSetTemp.ptVal) {
						let idx = this.$store.state.equipUpdateInfo.findIndex((el) => el === this.SETTEMP.ptAddr);

						if (idx !== -1) {
							this.$store.state.equipUpdateInfo.splice(idx, 1);
						}
					} else {
						if (this.$store.state.equipUpdateInfo.includes(this.SETTEMP.ptAddr) === false) {
							this.$store.commit("EQUIP_INFO_INSERT", this.SETTEMP.ptAddr);
						}
					}
					// }
				}

				this.isValidation();
			},
			onModeClick(ptVal) {
				if (ptVal == this.MODE.ptVal) {
					this.MODE.ptVal = 0;
				} else {
					this.MODE.ptVal = ptVal;
				}

				// if (Number(this.IsOn) === 1) {
				// this.$store.commit("UPDATE_TOTAL_REAL_LOCAL", this.MODE);

				if (this.MODE.ptVal === this.FirstMode.ptVal) {
					let idx = this.$store.state.equipUpdateInfo.findIndex((el) => el === this.MODE.ptAddr);

					if (idx !== -1) {
						this.$store.state.equipUpdateInfo.splice(idx, 1);
					}
				} else {
					if (this.$store.state.equipUpdateInfo.includes(this.MODE.ptAddr) === false) {
						this.$store.commit("EQUIP_INFO_INSERT", this.MODE.ptAddr);
					}
				}
				// }

				this.isValidation();
			},
			onFanClick(ptVal) {
				if (ptVal == this.FANSPEED.ptVal) {
					this.FANSPEED.ptVal = 0;
				} else {
					this.FANSPEED.ptVal = ptVal;
				}

				// if (Number(this.IsOn) === 1) {
				// this.$store.commit("UPDATE_TOTAL_REAL_LOCAL", this.FANSPEED);

				if (this.FANSPEED.ptVal === this.FirstFANSPEED.ptVal) {
					let idx = this.$store.state.equipUpdateInfo.findIndex((el) => el === this.FANSPEED.ptAddr);

					if (idx !== -1) {
						this.$store.state.equipUpdateInfo.splice(idx, 1);
					}
				} else {
					if (this.$store.state.equipUpdateInfo.includes(this.FANSPEED.ptAddr) === false) {
						this.$store.commit("EQUIP_INFO_INSERT", this.FANSPEED.ptAddr);
					}
				}
				// }

				this.isValidation();
			},
			async onSave() {
				// let param = [];

				// let param = [];
				let param = {
					ctrPtAddr: this.ctrPtAddr,
					propList: [],
				};

				for (let i = 0; i < this.detailCtrlPropList.length; i++) {
					if (this[this.detailCtrlPropList[i].propCode].ctrlPropYn === "Y") {
						param.propList.push({
							ptAddr: this[this.detailCtrlPropList[i].propCode].ptAddr,
							ptVal: this[this.detailCtrlPropList[i].propCode].ptVal,
						});
					}
				}

				// console.log(param, this.detailCtrlPropList);

				// param.propList.push({ptAddr: this.FANSPEED.ptAddr, ptVal: this.FANSPEED.ptVal});
				// param.propList.push({ptAddr: this.MODE.ptAddr, ptVal: this.MODE.ptVal});
				// param.propList.push({ptAddr: this.SETTEMP.ptAddr, ptVal: this.SETTEMP.ptVal});

				// let alert = await this.alertQuestion("변경 하시겠습니까?", "EHP 설비 제어");

				// if (!alert.value) {
				//   return;
				// } else {

				// }

				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
				↓↓↓↓↓
			*/
				try {
					let result = await backEndApi.outputLog.equipContrl(param);
					this.alertNoti(result.data.message);

					this.FirstFANSPEED = this.FANSPEED;
					this.FirstMode = this.MODE;
					this.FirstSetTemp = this.SETTEMP;

					this.isValidation();
				} catch (err) {
					new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger).errHandling();
				}
			},
			isValidation() {
				let FANSPEED = Number(this.FirstFANSPEED.ptVal);
				let MODE = Number(this.FirstMode.ptVal);
				let SETTEMP = Number(this.FirstSetTemp.ptVal);

				// this.propList.forEach((prop) => {
				//   if (prop.equipPropCode == "MODE") {
				//     MODE = prop.ptVal;
				//   } else if (prop.equipPropCode == "FAN") {
				//     FAN = prop.ptVal;
				//   } else if (prop.equipPropCode == "SETTEMP") {
				//     SETTEMP = prop.ptVal;
				//   }
				// });

				if (this.FANSPEED.ptVal === Number(FANSPEED) && this.MODE.ptVal === Number(MODE) && this.SETTEMP.ptVal === Number(SETTEMP)) {
					this.isShow = false;
					this.isEdit = false;
				} else {
					this.isShow = true;
					this.isEdit = true;
				}
			},
			resetVal() {
				this.FANSPEED = JSON.parse(JSON.stringify(this.FirstFANSPEED));
				this.MODE = JSON.parse(JSON.stringify(this.FirstMode));
				this.SETTEMP = JSON.parse(JSON.stringify(this.FirstSetTemp));

				this.isShow = false;
				this.isEdit = false;
			},
		},
	};
</script>
