<template>
	<!-- EHP list -->
	<b-row :id="'accordian'" cols="5" class="list_area ehpAccrodi" :style="locale === 'en' ? { fontSize: '10px', width: '100%' } : { width: '100%' }">
		<b-col
			class="list w-20"
			v-for="(equip, index) in equipList"
			:key="index"
			:class="'list' + index"
			:style="{ border: '1px solid #313131', margin: '5px 16px', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }"
		>
			<ul class="list_info" :style="{ justifyContent: 'space-evenly' }">
				<transition>
					<li class="icon">
						<img v-if="equip.iconFilePath === ''" src="@assets/default/icon/air.png" width="50" />
						<img v-else :src="`${xemsHosts}/equip/${equip.iconFilePath.split('equip')[1]}`" />
						<!-- <img src="/assets/xems/common/views/equip-control/ehp/icon_01.png" alt="equip.equipName"> -->
					</li>
				</transition>
				<transition>
					<li class="tit">
						{{ equip.equipName }}
						<!-- {{ equip. }} -->
					</li>
				</transition>
				<transition v-if="TEMP[index]" class="list" :class="`${TEMP[index].equipPropCode}${index}`">
					<transition>
						<li class="temperature col-2" :style="{ width: 'none' }">
							<strong>{{ Number(TEMP[index].ptVal).toFixed(0) }}</strong>
							<span> ℃</span>
						</li>
					</transition>
				</transition>

				<transition v-if="ONOFF[index]" class="list" :class="`${ONOFF[index].equipPropCode}${index}`">
					<transition>
						<li class="btn_control">
							<!-- {{ ONOFF[index].ptAddr }}
              {{ ONOFF[index].ptVal }} -->
							<!-- <button
									@click="onSaveOnOff($event.target, ctrPtAddr[index], index)"
									v-if="!liveData[equip.ctrPtAddr.split('CONTROL')[0] + 'ONOFF'] ? 0 : liveData[equip.ctrPtAddr.split('CONTROL')[0] + 'ONOFF'].ptVal ?? 0 == 0"
									type="button"
									class="off"
									value="0"
								>
									off
								</button>
								<button @click="onSnpm aveOnOff($event.target, ctrPtAddr[index], index)" v-else type="button" class="on" value="1">
									on
								</button> -->
							<button v-if="Number(ONOFF[index].ptVal) == 0" type="button" class="off" :value="0" @click="onSaveOnOff($event.target, ctrPtAddr[index], index)">
								off
							</button>
							<button v-if="Number(ONOFF[index].ptVal) == 1" type="button" class="on" :value="1" @click="onSaveOnOff($event.target, ctrPtAddr[index], index)">
								on
							</button>
						</li>
					</transition>
				</transition>
				<div class="more">
					<!-- <img src="/assets/xems/common/views/equip-control/ehp/icon_more.png" alt="" /> -->
				</div>
			</ul>

			<transition>
				<div>
					<div class="list_control open">
						<ehp-accordian-detail
							:ref="'equipListDetail' + index"
							v-if="propList"
							:ctrlPropList="ctrlPropList"
							:propList="equip.propList"
							:ctrPtAddr="ctrPtAddr[index]"
							:IsOn="ONOFF[index] ? ONOFF[index].ptVal : 0"
							:liveData="liveData"
						/>
					</div>
				</div>
			</transition>
		</b-col>
	</b-row>
</template>

<style scoped>
	/* .ehpAccrodi{
  width: 100% !important;
  min-width: 406px;
} */
	.fade-enter {
		opacity: 0;
	}
	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 1s ease-out;
	}
	.fade-leave-to {
		opacity: 0;
	}
</style>

<script>
	import backEndApi from "@api/backEndApi";
	import ehpAccordianDetail from "./EhpAccordianDetail.vue";
	import config from "@libs/config.js";
	import AppErrorHandler from "@src/appErrorHandler";
	import * as popupMessages from "@src/consts/popupMessageConsts";

	export default {
		components: {
			ehpAccordianDetail,
		},
		props: ["zoneInfo", "equipList", "equipListDetail", "ctrPtAddr", "ctrlPropList", "isShow"],
		data() {
			return {
				propList: null,
				ONOFF: [],
				TEMP: [],
				xemsHosts: config.backend.apiUrl,
				popupMessages,
			};
		},
		computed: {
      locale(){
        return this.$store.state.locale;
      },
			liveData() {
				console.log(this.$store.getters.getTotalReal);
				return this.$store.getters.getTotalReal ?? {};
			},
		},
		watch: {
			equipListDetail() {
				this.propList = this.equipListDetail;
			},
			liveData() {
				this.initOnOff();
			},
			equipList() {
				this.initOnOff();
			},
		},
		created() {},
		mounted() {
			this.init();
		},
		methods: {
			init() {
				this.propList = this.equipListDetail;
				// this.initHide();
				this.initOnOff();
			},
			initHide() {
				let accordianClass = document.querySelectorAll("#accordian")[0];
				let listControl = accordianClass.querySelectorAll(".list_control");

				listControl.forEach((element, index) => {
					if (index > 0) {
						element.classList.remove("open");
						element.hidden = true;
					} else {
						element.classList.add("open");
						element.hidden = false;
					}
				});
			},
			allHide() {
				// let accordianClass = document.querySelectorAll("#accordian")[0];
				// let listControl = accordianClass.querySelectorAll(".list_control");
				// listControl.forEach((element) => {
				//   element.classList.remove("open");
				//   element.hidden = true;
				// });
			},
			initOnOff() {
				this.ONOFF = [];
				this.TEMP = [];
				for (let i = 0; i < this.equipList.length; i++) {
					let equip = this.equipList[i];

					if (equip.propList.length !== 0) {
						for (let j = 0; j < equip.propList.length; j++) {
							let prop = equip.propList[j];
							if (prop.equipPropCode == "ONOFF") {
								this.ONOFF.push({
									ctrlPropYn: prop.ctrlPropYn,
									ptAddr: prop.ptAddr,
									ptVal: this.liveData[prop.ptAddr] === undefined ? prop.ptVal : this.liveData[prop.ptAddr].ptVal,
									// ptVal: prop.ptVal,
								});
							} else if (prop.equipPropCode === "TEMP") {
								this.TEMP.push({
									ctrlPropYn: prop.ctrlPropYn,
									ptAddr: prop.ptAddr,
									ptVal: this.liveData[prop.ptAddr] === undefined ? prop.ptVal : this.liveData[prop.ptAddr].ptVal,
									// ptVal: prop.ptVal,
								});
							}
						}
					} else {
						this.ONOFF.push({
							ctrlPropYn: "N",
							ptAddr: "",
							ptVal: 0,
						});
						this.TEMP.push({
							ctrlPropYn: "N",
							ptAddr: "",
							ptVal: 0,
						});
					}

					let target = document.getElementsByClassName("btn_control")[i]?.children[0];

					if (target) {
						if (Number(this.ONOFF[i].ptVal) === 0) {
							target.textContent = "off";
							target.classList.remove("on");
							target.classList.add("off");
						} else {
							target.textContent = "on";
							target.classList.remove("off");
							target.classList.add("on");
						}
					}
				}

				if (this.ONOFF.length <= 0) {
					for (let i = 0; i < this.ctrlPropList.length; i++) {
						let prop = this.ctrlPropList[i];

						if (prop.propCode == "ONOFF") {
							this.ONOFF.push({
								ctrlPropYn: prop.ctrlPropYn,
								ptAddr: prop.ptAddr,
								ptVal: this.liveData[prop.ptAddr] === undefined ? prop.ptVal : this.liveData[prop.ptAddr].ptVal,
								// ptVal: prop.ptVal,
							});
							this.TEMP.push({
								ctrlPropYn: prop.ctrlPropYn,
								ptAddr: prop.ptAddr,
								ptVal: this.liveData[prop.ptAddr] === undefined ? prop.ptVal : this.liveData[prop.ptAddr].ptVal,
								// ptVal: prop.ptVal,
							});
						} else {
							this.ONOFF.push({
								ctrlPropYn: "N",
								ptAddr: "",
								ptVal: 0,
							});
							this.TEMP.push({
								ctrlPropYn: "N",
								ptAddr: "",
								ptVal: 0,
							});
						}

						// let target = document.getElementsByClassName("btn_control")[i]?.children[0];

						// if (target) {
						// 	if (Number(this.ONOFF[i].ptVal) === 0) {
						// 		target.textContent = "off";
						// 		target.classList.remove("on");
						// 		target.classList.add("off");
						// 	} else {
						// 		target.textContent = "on";
						// 		target.classList.remove("off");
						// 		target.classList.add("on");
						// 	}
						// }
					}
				}

				// this.equipList.forEach((equip) => {
				//   equip.propList.forEach((prop) => {
				//     if (prop.equipPropCode == "ONOFF") {
				//       console.log(this.liveData[prop.ptAddr]);
				//       this.ONOFF.push({
				//         ctrlPropYn: prop.ctrlPropYn,
				//         ptAddr: prop.ptAddr,
				//         ptVal: this.liveData[prop.ptAddr] === undefined ? 0 : this.liveData[prop.ptAddr].ptVal,
				//       });
				//     }
				//   });
				// });
			},

			async onSaveOnOff(target, ctrPtAddr, idx) {
				let value;
				// if (target.textContent == "on") {
				// 	value = 0;
				// } else {
				// 	value = 1;
				// }
				if (Number(this.ONOFF[idx].ptVal) === 1) {
					value = 0;
				} else {
					value = 1;
				}

				let param = {
					ctrPtAddr: ctrPtAddr,
					propList: [],
				};

				for (let i = 0; i < this.ctrlPropList.length; i++) {
					if (this.ctrlPropList[i].propCode === "ONOFF") {
						if (this[this.ctrlPropList[i].propCode][idx].ctrlPropYn === "Y") {
							param.propList.push({
								ptAddr: this[this.ctrlPropList[i].propCode][idx].ptAddr,
								ptVal: value,
							});
							break;
						}
					}
				}

				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
					↓↓↓↓↓
				*/

				try {
					if (param.propList.length <= 0) return;

					let result = await backEndApi.outputLog.equipContrl(param);
					let message = "제어 명령 전송이 완료되었습니다.";
					result.data.message = message;
					this.alertNoti(result.data.message);
					// if (this.ONOFF[idx].ptVal === 0) {
					// 	target.textContent = "off";
					// 	target.classList.remove("on");
					// 	target.classList.add("off");
					// } else {
					// 	target.textContent = "on";
					// 	target.classList.remove("off");
					// 	target.classList.add("on");
					// }
					this.$store.commit("EQUIP_INFO_INSERT", this.ONOFF[idx].ptAddr);
					// this.$store.commit("UPDATE_TOTAL_REAL_LOCAL", this.ONOFF[idx]);
				} catch (err) {
					new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger).errHandling();

					if (this.ONOFF[idx].ptVal === 0) {
						this.ONOFF[idx].ptVal = 1;
					} else {
						this.ONOFF[idx].ptVal = 0;
					}
				}
			},
			getEquipListDetail(data) {
				this.$emit("getEquipListDetail:data", data);
			},
		},
	};
</script>
